@charset "UTF-8";
@font-face {
  font-family: "sunny4";
  src: url("../fonts/sunny4/fonts/sunny4.eot");
  src: url("../fonts/sunny4/fonts/sunny4.eot?#iefix") format("embedded-opentype"), url("../fonts/sunny4/fonts/sunny4.woff") format("woff"), url("../fonts/sunny4/fonts/sunny4.ttf") format("truetype"), url("../fonts/sunny4/fonts/sunny4.svg#sunny4") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "sunny4" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sunny4" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "a";
}

.icon-almost-full:before {
  content: "b";
}

.icon-almost-old:before {
  content: "c";
}

.icon-cloudy:before {
  content: "d";
}

.icon-cloudy-day:before {
  content: "e";
}

.icon-delete:before {
  content: "f";
}

.icon-dust:before {
  content: "g";
}

.icon-empty-moon:before {
  content: "h";
}

.icon-fog:before {
  content: "i";
}

.icon-full-moon:before {
  content: "j";
}

.icon-grow-moon:before {
  content: "k";
}

.icon-heavy-rain:before {
  content: "l";
}

.icon-heavy-snow:before {
  content: "m";
}

.icon-humidity:before {
  content: "n";
}

.icon-menu:before {
  content: "o";
}

.icon-night:before {
  content: "q";
}

.icon-clear-night:before {
  content: "q";
}

.icon-night-cloudy:before {
  content: "r";
}

.icon-night-less-cloudy:before {
  content: "s";
}

.icon-partly-cloudy-night:before {
  content: "s";
}

.icon-old-moon:before {
  content: "t";
}

.icon-partly-cloudy:before {
  content: "u";
}

.icon-partly-cloudy-day:before {
  content: "u";
}

.icon-rain:before {
  content: "v";
}

.icon-rain-snow:before {
  content: "w";
}

.icon-settings:before {
  content: "x";
}

.icon-shower:before {
  content: "y";
}

.icon-sleet:before {
  content: "z";
}

.icon-sleet-rain:before {
  content: "A";
}

.icon-snow:before {
  content: "B";
}

.icon-sunny:before {
  content: "C";
}

.icon-clear-day:before {
  content: "C";
}

.icon-sunrise:before {
  content: "D";
}

.icon-sunset:before {
  content: "E";
}

.icon-thundershowers-sunny:before {
  content: "F";
}

.icon-thunderstorm:before {
  content: "G";
}

.icon-wind:before {
  content: "H";
}

.icon-wind-direction:before {
  content: "I";
}

.icon-young-moon:before {
  content: "J";
}

.icon-active-window:before {
  content: "K";
}

.icon-next-window:before {
  content: "p";
}

.icon-refresh:before {
  content: "L";
}

.icon-check:before {
  content: "M";
}