@import "components/variables";
@import "components/font-face";
@import "components/reset";
@import "components/utilities";
@import "../fonts/sunny4/styles";

.ui-widget-content {
  height: 10px;
  border: none;
  background: transparent;
}

.ui-slider-horizontal {
  background-color: rgba(0, 0, 0, 0.5);
}

.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #081322;
  }
}

.ui-widget-header {
  background: #ff4200 none;
  transition: width 0.5s ease;
}

.ui-slider-horizontal .ui-slider-handle {
  transition: left 0.5s ease;
}



.ps-container > .ps-scrollbar-x-rail {
  opacity: 1;
}

.ps-container>.ps-scrollbar-x-rail>.ps-scrollbar-x {
  background-color: #fff;
}


html {
  font-family: $primary-font-stack;
  font-size: 62.5%;
  @media screen and (max-width: $breakpoint-fontsize) {
    font-size: 50%;
  }
}

body {
  max-width: $width-body-max;
  min-width: $width-body-min;
  margin: 0 auto;
  color: $color-text-primary;
  background-color: $color-day-cold;
}

@import "components/body-backgrounds";
@import "components/weather-animation";

#sun-block {
  position: fixed;
  z-index: -1;
  bottom: -200px;
  left: -200px;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle closest-side at 50%, #fff000 0%, rgba(255, 84, 0, 0.65) 34%, rgba(204, 222, 227, 0) 100%) center no-repeat;
}

.main {
  display: block; //for IE
  width: $width-main;
  padding: 10px 0 0 10px;
  overflow: hidden;
  @media screen and (max-width: $breakpoint-main-stretch) {
    width: 95%;
  }
  @media screen and (max-width: $breakpoint-fontsize) {
    padding-top: 50px;
    width: 99%;
  }
}

@import "components/dashboard";
@import "components/sidebar";
@import "components/setting";