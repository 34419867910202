@font-face {
  font-family: 'PT Sans Narrow';
  src: url('../fonts/PT-Sans/PTN57F.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url('../fonts/PT-Sans/PTN77F.ttf');
  font-style: normal;
  font-weight: bold;
}

